import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { map, tap, delay, filter } from 'rxjs/operators';
import { MedRegServiceProxy, SearchMedRegPersonInput, MedRegWbtDto, MedRegBewilligungDto, GetMedRegPersonDetailedOutput, CantonSpecificsServiceProxy } from '@shared/service-proxies/service-proxies';
import { DatePipe } from '@angular/common';
import { DateTime } from 'luxon';
import { AppConsts } from '@shared/AppConsts';
import { LocalizationService, PermissionCheckerService } from 'abp-ng2-module';
import { HealthRegisterBaseService } from './health-register-base.service';
import { HealthRegisterDiploma } from './dto/health-register-diploma';
import { HealthRegisterPostgraduateTitle } from './dto/health-register-postgraduate-title';
import { HealthRegisterPostgraduateTitlePrivateLaw } from './dto/health-register-postgraduate-title-private-law';

@Injectable()
export class MedRegService extends HealthRegisterBaseService {

    latestMedRegPerson: GetMedRegPersonDetailedOutput;
    requestedId = undefined;
    medRegPersonSubject = new ReplaySubject<GetMedRegPersonDetailedOutput>(1);
    isAuthority: boolean;

    constructor(
        protected datePipe: DatePipe,
        protected localization: LocalizationService,
        protected cantonSpecificsServiceProxy: CantonSpecificsServiceProxy,
        private medRegService: MedRegServiceProxy,
        private permissionChecker: PermissionCheckerService
    ) {
        super(datePipe, localization, cantonSpecificsServiceProxy);
        this.isAuthority = this.permissionChecker.isGranted('Pages.Authority');
    }

    get hasInactiveLicences(): boolean {
        const licences = this.getLicences();
        return licences?.some((l) => l.aktivitaet?.sironaId === AppConsts.codes.external.medreg.activity.inactive);
    }

    get hasRestrictedLicences(): boolean {
        const licences = this.getLicences();
        return licences?.some((l) => l.status.sironaId === AppConsts.codes.professionalLicenceStatus.restricted);
    }

    get hasInactiveLicencesInOtherCantons(): boolean {
        const licences = this.getLicences();
        return licences?.some((l) => l.aktivitaet?.sironaId === AppConsts.codes.external.medreg.activity.inactive && l.kanton.sironaId !== this.cantonId);
    }

    get hasInactiveLicencesInCanton(): boolean {
        const licences = this.getLicences();
        return licences?.some((l) => l.aktivitaet?.sironaId === AppConsts.codes.external.medreg.activity.inactive && l.kanton.sironaId === this.cantonId);
    }

    search(input: SearchMedRegPersonInput) {
        return this.medRegService.search(input);
    }

    getPerson(registerPersonId: number, reload: boolean = false): Observable<GetMedRegPersonDetailedOutput | string> {
        if (this.requestedId && this.requestedId === registerPersonId && !reload) {
            return this.medRegPersonSubject.asObservable().pipe(filter(Boolean));
        }

        this.requestedId = registerPersonId;
        this.latestMedRegPerson = undefined;
        this.medRegPersonSubject.next(null);

        if (this.isAuthority) {
            return this.medRegService.getDetailed(registerPersonId).pipe(
                tap((output: GetMedRegPersonDetailedOutput) => {
                    this.latestMedRegPerson = output;
                    this.medRegPersonSubject.next(output);
                })
            );

        } else{
            return this.medRegService.get(registerPersonId).pipe(
                tap((output: GetMedRegPersonDetailedOutput) => {
                    this.latestMedRegPerson = output;
                    this.medRegPersonSubject.next(output);
                })
            );
        }
   }

    getPersonDetailedByGln(gln: string): Observable<GetMedRegPersonDetailedOutput> {
        return this.medRegService.getDetailedByGln(gln).pipe(
            tap((output: GetMedRegPersonDetailedOutput) => {
                this.requestedId = output.medRegWholePerson.person.id;
                this.latestMedRegPerson = output;
            })
        );
    }

    get fullName(): string {
        let person = this.latestMedRegPerson?.medRegWholePerson?.person;
        let name = person?.vorname;

        if (person?.nachname) {
            if (name) {
                name = name + ' ' + person.nachname;
            } else {
                name = person.nachname;
            }
        }

        if (person?.ledigerName && person.ledigerName.replace(' ', '').length > 0) {
            if (name) {
                name = name + ' (' + person.ledigerName + ')';
            } else {
                name = '(' + person.ledigerName + ')';
            }
        }

        return name;
    }

    get nationality1(): string {
        return this.latestMedRegPerson?.medRegWholePerson?.person?.nationalitaet1?.sironaId;
    }

    get nationality2(): string {
        return this.latestMedRegPerson?.medRegWholePerson?.person?.nationalitaet2?.sironaId;
    }

    get birthYear(): string {
        return this.latestMedRegPerson?.medRegWholePerson?.person?.geburtsjahr;
    }

    get ahv(): string {
        return this.latestMedRegPerson?.medRegWholePerson?.person?.ahvNr;
    }

    get gender(): string {
        return this.latestMedRegPerson?.medRegWholePerson?.person?.geschlecht?.sironaId;
    }

    get gln(): string {
        return this.latestMedRegPerson?.medRegWholePerson?.person?.gln;
    }

    get firstName(): string {
        return this.latestMedRegPerson?.medRegWholePerson?.person?.vorname;
    }

    get lastName(): string {
        return this.latestMedRegPerson?.medRegWholePerson?.person?.nachname;
    }

    get birthName(): string {
        return this.latestMedRegPerson?.medRegWholePerson?.person?.ledigerName;
    }

    get placeOfOrigin(): string {
        return this.latestMedRegPerson?.medRegWholePerson?.person?.heimatort;
    }

    get languageSkills(): string[] {
        if (this.latestMedRegPerson?.medRegWholePerson == null) {
            return [];
        }

        const languageSkills = this.latestMedRegPerson.medRegWholePerson.person.sprachkompetenzen
                                        .filter(x => x.sironaId !== null)
                                        .map(x => x.sironaId);
        return languageSkills;
    }

    get languageSkillsAsCommaSeparatedList(): string {
        let skills = this.languageSkills;
        return skills?.length > 0 ? skills.map(s => this.localization.localize(s, this.lSource)).join(', ') : null;
    }

    get birthDate(): string {
        return this.datePipe.transform(this.latestMedRegPerson?.medRegWholePerson?.person?.geburtsdatum.toJSDate());
    }

    get queryTime(): DateTime {
        return this.latestMedRegPerson?.medRegWholePerson?.queryTime;
    }

    get diplomas(): string[] {
        let list: string[] = [];
        let diplome = this.latestMedRegPerson?.medRegWholePerson?.medRegDiplome;
        if (diplome) {
            for (let i = 0; i < diplome.length; i++) {
                list.push(
                    this.localization.localize(diplome[i].art.sironaId, this.lSource) +
                        ', ' +
                        this.resolveDate(diplome[i].datumErteilung)
                );
            }
        }
        return list;
    }

    getProfessionDiplomaForComparison(professionId: string): HealthRegisterDiploma {
        let diplome = this.latestMedRegPerson?.medRegWholePerson?.medRegDiplome;
        if (diplome) {
            const d = diplome.find(x => x.art.sironaId === professionId);
            if (d == null) {
                return null;
            } else {
                const healthRegisterDiploma = new HealthRegisterDiploma();
                healthRegisterDiploma.professionId = d.art.sironaId;
                healthRegisterDiploma.typeId = d.typ.sironaId;
                healthRegisterDiploma.countryId = d.land.sironaId;
                healthRegisterDiploma.dateOfIssue = this.resolveDate(d.datumErteilung);
                healthRegisterDiploma.dateOfRecognition = this.resolveDate(d.datumAnerkennungCH);
                healthRegisterDiploma.placeOfIssue = d.ortErteilung;
                return healthRegisterDiploma;
            }
        }
    }

    getPostgraduateTitlesForComparison(): HealthRegisterPostgraduateTitle[] {
        let titles = this.latestMedRegPerson?.medRegWholePerson?.medRegWbts;
        if (titles) {
            const healthRegisterPostgraduateTitles: HealthRegisterPostgraduateTitle[] = [];
            for (let i = 0; i < titles.length; i++) {
                const healthRegisterPostgraduateTitle = new HealthRegisterPostgraduateTitle();
                healthRegisterPostgraduateTitle.titleId = titles[i].art.sironaId;
                healthRegisterPostgraduateTitle.typeId = titles[i].typ.sironaId;
                healthRegisterPostgraduateTitle.dateOfIssue = this.resolveDate(titles[i].datumErteilung);
                healthRegisterPostgraduateTitle.dateOfRecognition = this.resolveDate(titles[i].datumAnerkennungCH);
                healthRegisterPostgraduateTitle.placeOfIssue = titles[i].ortErteilung;
                healthRegisterPostgraduateTitles.push(healthRegisterPostgraduateTitle);
            }
            return healthRegisterPostgraduateTitles;
        }
    }


    getPostgraduateTitlesPrivateLawForComparison(): HealthRegisterPostgraduateTitlePrivateLaw[] {
        let titlesPrivateLaw = this.latestMedRegPerson?.medRegWholePerson?.medRegPwbs;
        if (titlesPrivateLaw) {
            const healthRegisterPostgraduateTitlesPrivateLaw: HealthRegisterPostgraduateTitlePrivateLaw[] = [];
            for (let i = 0; i < titlesPrivateLaw.length; i++) {
                const healthRegisterPostgraduateTitlePrivateLaw = new HealthRegisterPostgraduateTitlePrivateLaw();
                healthRegisterPostgraduateTitlePrivateLaw.name = titlesPrivateLaw[i].art.sironaId;
                healthRegisterPostgraduateTitlePrivateLaw.type = titlesPrivateLaw[i].typ.sironaId;
                healthRegisterPostgraduateTitlePrivateLaw.dateOfIssue = this.resolveDate(titlesPrivateLaw[i]?.datumErteilung);
                healthRegisterPostgraduateTitlesPrivateLaw.push(healthRegisterPostgraduateTitlePrivateLaw);
            }
            return healthRegisterPostgraduateTitlesPrivateLaw;
        }
    }

    get postgraduateTitles(): string[] {
        let list: string[] = [];
        let titles = this.latestMedRegPerson?.medRegWholePerson?.medRegWbts;
        if (titles) {
            for (let i = 0; i < titles.length; i++) {
                list.push(
                    this.resolveLanguage(titles[i].weiterbildungsartLabel) +
                        ', ' +
                        this.resolveLanguage(titles[i].weiterbildungstypLabel) +
                        ', ' +
                        this.resolveDate(titles[i].datumErteilung)
                );
            }
        }
        return list;
    }

    private displayCorrectWbtDate(wbt: MedRegWbtDto): string {
        if (wbt.typ.healthRegisterId === AppConsts.codes.external.medreg.weiterbildungstitel.typ.annerkanterMebeko ||
            wbt.typ.healthRegisterId === AppConsts.codes.external.medreg.weiterbildungstitel.typ.gleichwertigerMebeko) {
                return this.resolveDate(wbt.datumAnerkennungCH);
            }

        return this.resolveDate(wbt.datumErteilung);
    }


    get normalLicences(): string[] {
        const list = [];
        const normalLicences = this.getLicences().filter((l) => l.typ.healthRegisterId !== 4004);

        if (normalLicences) {
            for (let i = 0; i < normalLicences.length; i++) {
                let licenceDescription = `${this.localization.localize(normalLicences[i].diplomArt?.sironaId, this.lSource)},
                ${this.localization.localize(normalLicences[i].kanton.sironaId, this.lSource)},
                ${this.resolveDate(normalLicences[i].datumEntscheid)},
                ${this.localization.localize(normalLicences[i].status?.sironaId, this.lSource)}`;
                if (normalLicences[i].aktivitaetLabel != null) {
                    licenceDescription += ', ' + this.resolveLanguage(normalLicences[i]?.aktivitaetLabel);
                }
                list.push(licenceDescription);
            }
        }

        return list;
    }

    get dl90Licences(): string[] {
        const list = [];
        const dl90licences = this.getLicences().filter((l) => l.typ.healthRegisterId === 4004);

        if (dl90licences) {
            for (let i = 0; i < dl90licences.length; i++) {
                list.push(
                    `${this.localization.localize(dl90licences[i].diplomArt?.sironaId, this.lSource)},
                     ${this.localization.localize(dl90licences[i].kanton.sironaId, this.lSource)},
                     ${this.datePipe.transform(dl90licences[i].datumEntscheid?.toJSDate())},
                     ${this.resolveLanguage(dl90licences[i].neunzigTageDienstleisterLabel)}`
                );
            }
        }
        return list;
    }

    get cantonalLicenceRestrictions(): string[] {
        return this.latestMedRegPerson?.medRegWholePerson?.person?.cantonalLicenceRestrictions;
    }

    getLicences(): MedRegBewilligungDto[] {
        let list: MedRegBewilligungDto[] = [];
        let diplomas = this.latestMedRegPerson?.medRegWholePerson?.medRegDiplome;
        if (diplomas) {
            for (let i = 0; i < diplomas.length; i++) {
                if (diplomas[i].medRegBewilligungen) {
                    for (let j = 0; j < diplomas[i].medRegBewilligungen.length; j++) {
                        list.push(diplomas[i].medRegBewilligungen[j]);
                    }
                }
            }
        }
        return list;
    }

    get hasSensitiveData(): boolean {
        return this.latestMedRegPerson?.medRegWholePerson?.person?.weitereInfoVorhanden;
    }

    get hasSensitiveDataCantonalLaw(): boolean {
        return this.latestMedRegPerson?.medRegWholePerson?.person?.schuetzenswertGemKantRecht;
    }

    private getDate(licence: MedRegBewilligungDto): DateTime {
        if (licence.datumAktivitaet) {
            return licence.datumAktivitaet;
        }
        return licence.datumEntscheid;
    }

    resolveDate(date) {
        return (date == null) ? '-' : this.datePipe.transform(date.toJSDate());
    }

    getPostgraduateTitlesForPerson(registerPersonId: number): Observable<string[] | string> {
        return this.getPerson(registerPersonId).pipe(
            map((output: GetMedRegPersonDetailedOutput) => output.medRegWholePerson.medRegWbts.map((wbt: MedRegWbtDto) => wbt.art.sironaId))
        );
    }

    clearCacheOnIdChange(medregId: number): void {
        if (this.latestMedRegPerson?.medRegWholePerson?.person?.id !== medregId) {
            this.latestMedRegPerson = null;
        }
    }
}
